import cn from "classnames";
import styles from "./styles/MainSection.module.css";

interface MainSectionProps {
  onContactButtonClick: () => void;
}

export default function MainSection({
  onContactButtonClick,
}: MainSectionProps) {
  return (
    <main className={styles.main}>
      <div className={styles.content}>
        <img
          src={`${process.env.PUBLIC_URL}/assets/photos/jjdn.jpg`}
          alt="JJDN"
          className={styles.image}
        />
        <h1>Code with Passion, Create with Purpose</h1>
        <p>
          We believe that technology should be a driver of progress,
          not a barrier.
        </p>
        <div className={styles.bottom}>
          <button className={cn(styles.demoButton, styles.outlined)}>
            View Portfolio
          </button>
          <button className={styles.demoButton} onClick={onContactButtonClick}>
            Get in touch
          </button>
        </div>
      </div>
    </main>
  );
}
