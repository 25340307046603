import cn from "classnames";
import { IoIosPhonePortrait } from "react-icons/io";
import { IoLocationOutline } from "react-icons/io5";
import { MdOutlineMailOutline } from "react-icons/md";
import Rating from "../Rating";
import styles from "./styles.module.css";

export default function ContactModal({
  showModal,
  onClose,
}: {
  showModal?: boolean;
  onClose?: () => void;
}) {
  return (
    <>
      <div
        id="myNav"
        className={cn(styles.overlay, {
          [styles.open]: showModal,
        })}
      >
        <a
          href="#"
          aria-label="Close modal"
          className={styles.closebtn}
          onClick={onClose}
        >
          &times;
        </a>
        <div className={styles["overlay-content"]}>
          <h1>Get in touch</h1>
          <p>
            We value your feedback, inquiries, and suggestions. Whether you have
            a question about our products or services, want to report an issue,
            or simply want to reach out to us, we're here to assist you.
          </p>
          <div className={styles.Options}>
            <div>
              <IoLocationOutline />
              <h2>Location</h2>
              <a href="https://www.google.com/maps/place/Chicago,+IL/@41.8337329,-87.7319639,11z/data=!3m1!4b1!4m6!3m5!1s0x880e2c3cd0f4cbed:0xafe0a6ad09c0c000!8m2!3d41.8781136!4d-87.6297982!16zL20vMDFfZDQ">
                <span>Chicago, IL</span>
              </a>
            </div>
            <div>
              <IoIosPhonePortrait />
              <h2>Phone</h2>
              <a href="tel: 7085681041">
                <span>TBD</span>
              </a>
            </div>
            <div>
              <MdOutlineMailOutline />
              <h2>Email</h2>
              <a href="mailto: contact@jjdn.org">
                <span>contact@jjdn.org</span>
              </a>
            </div>
          </div>
          <div className={styles.RatingContainer}>
            <span>Enjoying your time on our site?</span>
            <Rating />
          </div>
        </div>
      </div>
    </>
  );
}
