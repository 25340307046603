import CreateEventTemplate from "./CreateEvent";

export default function EventsTemplate() {
  return {
    id: "events-template",
    title: "Managing Events",
    description:
      "Documentation that can help assist you when creating, updating, deleting or publishing an event.",
    category: "Events",
    parent: "",
    children: [CreateEventTemplate()],
    documentation: [
      {
        type: "children",
      },
    ],
  };
}
