import cn from "classnames";
import { useNavigate } from "react-router-dom";
import { ReactComponent as DownCarotSVG } from "./../../icons/down-carot.svg";
import { ReactComponent as RightCarotSVG } from "./../../icons/right-carot.svg";
import styles from "./styles/DocumentationNav.module.css";

interface DocumentationNavProps {
  templates: any[];
  selectedLink: string;
  onClick: (id: string) => void;
}

export default function DocumentationNav({
  templates,
  onClick,
  selectedLink,
}: DocumentationNavProps) {
  const navigate = useNavigate();
  const isChildOpen = (title: string) => {
    const found = templates.find((template) => template.title === selectedLink);
    return found.parent !== "" && found.parent === title;
  };

  const renderCarot = (title: string) => {
    if (title === selectedLink || isChildOpen(title)) {
      return <DownCarotSVG className={styles.carot} />;
    }
    return <RightCarotSVG className={styles.carot} />;
  };

  const renderChildren = (children: any[], parent: any) => {
    if (parent.title !== selectedLink && !isChildOpen(parent.title)) {
      return null;
    }

    if (isChildOpen(parent.title)) {
      return parent.children.map((child: any) => {
        return (
          <div className={styles.childLink}>
            <p
              key={child.id}
              className={cn({
                [styles.selected]: selectedLink === child.title,
              })}
              onClick={() => onClick(child.title)}
            >
              {child.title}
            </p>
          </div>
        );
      });
    }

    return children.map((child) => {
      return (
        <div className={styles.childLink}>
          <p
            key={child.id}
            className={cn({
              [styles.selected]: selectedLink === child.title,
            })}
            onClick={() => onClick(child.title)}
          >
            {child.title}
          </p>
        </div>
      );
    });
  };

  const renderParents = () => {
    return templates.map((template) => {
      return (
        !template.parent && (
          <>
            <div className={styles.parentLink}>
              <p
                key={template.id}
                className={cn({
                  [styles.selected]:
                    selectedLink === template.title ||
                    isChildOpen(template.title),
                })}
                onClick={() => onClick(template.title)}
              >
                {template.title}
              </p>
              {template.children.length > 0 && renderCarot(template.title)}
            </div>
            {renderChildren(template.children, template)}
          </>
        )
      );
    });
  };

  return (
    <div className={styles.DocumentationNav}>
      {renderParents()}
      <span
        className={cn(styles.ReturnLink, "link")}
        onClick={() => navigate("/")}
      >
        Go to JJDN.org
      </span>
    </div>
  );
}
