import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import DocumentationSearch from "./DocumentationSearch";
import styles from "./styles/DocumentationBody.module.css";

interface DocumentationBodyProps {
  templates: any[]; // Temporary
  documentation: any[];
  children: any[];
  onClick: (title: string) => void;
}

export default function DocumentationBody({
  templates,
  documentation,
  children,
  onClick,
}: DocumentationBodyProps) {
  const renderComponent = (documentation: any) => {
    switch (documentation.type) {
      case "children":
        return children.map((child) => (
          <div key={child.title} className={styles.links}>
            <span className="link" onClick={() => onClick(child.title)}>
              {child.title}
            </span>
            <p>{child.description}</p>
          </div>
        ));
      case "header":
        return <h1 className={styles.header}>{documentation.value}</h1>;
      case "sub-header":
        return <h1 className={styles.subHeader}>{documentation.value}</h1>;
      case "text":
        return <p className={styles.description}>{documentation.value}</p>;
      case "link":
        return (
          <a href={documentation.value} className={styles.link}>
            {documentation.displayName || documentation.value}
          </a>
        );
      case "image":
        return (
          <Zoom>
            <img
              src={`${process.env.PUBLIC_URL}${documentation.value}`}
              alt={documentation.alt}
              className={styles.image}
            />
          </Zoom>
        );
      case "overview":
        return (
          <div className={styles.overview}>
            <DocumentationSearch templates={templates} onClick={onClick} />
          </div>
        );
      default:
        return <></>;
    }
  };

  return (
    <div className={styles.DocumentationBody}>
      {documentation.map((documentation) => renderComponent(documentation))}
    </div>
  );
}
