import { useCallback } from "react";
import styles from "./styles/DocumentationSearch.module.css";

interface DocumentationSearchProps {
  templates: any[];
  searchValue?: string;
  onClick: (title: string) => void;
}

export default function DocumentationSearch({
  searchValue = "",
  templates,
  onClick,
}: DocumentationSearchProps) {
  const renderResults = useCallback(() => {
    const components = templates.reduce((filtered, template) => {
      if (template.title.toLowerCase().includes(searchValue?.toLowerCase())) {
        const component = (
          <div key={template.title} className={styles.links}>
            <span className="link" onClick={() => onClick(template.title)}>
              {template.title}
            </span>
            <p>{template.description}</p>
          </div>
        );

        filtered.push(component);
      }
      return filtered;
    }, []);

    return components;
  }, [templates, searchValue, onClick]);

  return <div className={styles.DocumentationSearch}>{renderResults()}</div>;
}
