import Card from "../shared/Card";
import styles from "./styles/OurTeamSection.module.css";

export default function OurTeamSection() {
  return (
    <section className={styles.OurTeam}>
      <h1>Our Team</h1>
      <span className={styles.description}>
        We are a team of experienced software engineers dedicated to delivering
        high-quality solutions to our clients.
      </span>
      <div className={styles.Cards}>
        <Card
          title="Darius Harrison"
          description="Software Engineer | 5 Years Experience"
          imageUrl={`${process.env.PUBLIC_URL}/assets/photos/darius.jpg`}
          instagramUsername="dariusharrison"
          githubUsername="daharri"
          linkedinUsername="darius-harrison"
        />

        <Card
          title="Joshua Jones"
          description="Product Manager | 7 Years Experience"
          imageUrl={`${process.env.PUBLIC_URL}/assets/photos/jj.png`}
        />

        <Card
          title="Nick Winfield"
          description="Software Engineer | 6 Years Experience"
          imageUrl={`${process.env.PUBLIC_URL}/assets/photos/nick.png`}
        />

        <Card
          title="Joshua Baldwin"
          description="Software Engineer | 6 Years Experience"
          imageUrl={`${process.env.PUBLIC_URL}/assets/photos/jb.png`}
        />
      </div>
    </section>
  );
}
