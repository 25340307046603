export default function CreateEventTemplate() {
  return {
    id: "how-to-events-template",
    title: "How to create an event",
    description: "Instructions for creating and publishing an event.",
    category: "Events",
    parent: "Managing Events",
    children: [],
    documentation: [
      {
        type: "header",
        value: "Creating an event",
      },
      {
        type: "text",
        value: "1. Navigate to the events page - /events",
      },
      {
        type: "text",
        value: "2. Click the edit button at the top of the screen",
      },
      {
        type: "image",
        value: "/assets/docs/edit-events.png",
        alt: "Edit button on events page with red circle",
      },
      {
        type: "text",
        value:
          "3. Hover over the events section and click the MANAGE EVENTS button that appears in the upper right side of the screen.",
      },
      {
        type: "image",
        value: "/assets/docs/manage-events.png",
        alt: "Manage events button on events page with red circle",
      },
      {
        type: "text",
        value:
          "- An Events side panel should appear on the left side of the screen",
      },
      {
        type: "text",
        value: "Click the plus button to create a new event.",
      },
      {
        type: "image",
        value: "/assets/docs/create-event.png",
        alt: "Manage events button on events page with red circle",
      },
      {
        type: "text",
        value: "For more information about how to create an event, visit",
      },
      {
        type: "link",
        value:
          "https://support.squarespace.com/hc/en-us/articles/206543837-Events-pages?platform=v6&websiteId=65f0d79e919bfc760c8482d7#toc-add-an-event",
      },
    ],
  };
}
