import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Documentation from "./components/Documentation";
import Home from "./components/Home";
import RootLayout from "./layouts/RootLayout";
import reportWebVitals from "./reportWebVitals";
import "./styles/globals.css";
import DocumentationLayout from "./layouts/DocumentationLayout";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <RootLayout>
        <Home />
      </RootLayout>
    ),
  },
  {
    path: "/documentation",
    element: (
      <DocumentationLayout>
        <Documentation />
      </DocumentationLayout>
    ),
  },
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
