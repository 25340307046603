import { useState } from "react";
import styles from "./Home.module.css";
import MainSection from "./sections/MainSection";
import OurTeamSection from "./sections/OurTeamSection";
import ContactModal from "./shared/ContactModal";

export default function Home() {
  const [showContactModal, setShowContactModal] = useState<boolean>(false);

  return (
    <div className={styles.Home}>
      <ContactModal
        showModal={showContactModal}
        onClose={() => setShowContactModal(false)}
      />
      <MainSection
        onContactButtonClick={() => setShowContactModal(!showContactModal)}
      />
      <OurTeamSection />
    </div>
  );
}
