import { ReactNode } from "react";
import Footer from "../components/shared/Footer";
import Navbar from "../components/shared/Navbar";
import styles from "./RootLayout.module.css";

interface RootLayoutProps {
  children: ReactNode;
}

export default function RootLayout({ children }: RootLayoutProps) {
  return (
    <div className={styles.RootLayout}>
      <Navbar />
      {children}
      <Footer />
    </div>
  );
}
