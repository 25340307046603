import styles from "./styles.module.css";

interface CardProps {
  title: string;
  description: string;
  imageUrl: string;
  linkedinUsername?: string;
  githubUsername?: string;
  instagramUsername?: string;
}

export default function Card({
  title,
  description,
  imageUrl,
  linkedinUsername,
  githubUsername,
  instagramUsername,
}: CardProps) {
  return (
    <div className={styles.card}>
      <img src={imageUrl} alt={title} className={styles.image} />
      <div className={styles.content}>
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.description}>{description}</p>
      </div>
      <div className={styles.socials}>
        <img
          src={`${process.env.PUBLIC_URL}/assets/icons/linkedin.svg`}
          onClick={() =>
            linkedinUsername &&
            window.open(`https://www.linkedin.com/in/${linkedinUsername}/`)
          }
          alt="Linkedin icon"
          className={styles.icon}
        />
        <img
          src={`${process.env.PUBLIC_URL}/assets/icons/github.svg`}
          alt="Github icon"
          onClick={() =>
            githubUsername &&
            window.open(`https://www.github.com/${githubUsername}/`)
          }
          className={styles.icon}
        />
        <img
          src={`${process.env.PUBLIC_URL}/assets/icons/instagram.svg`}
          alt="Instagram icon"
          onClick={() =>
            instagramUsername &&
            window.open(`https://www.instagram.com/${instagramUsername}/`)
          }
          className={styles.icon}
        />
      </div>
    </div>
  );
}
