export default function WelcomeTemplate() {
  return {
    id: "welcome-template",
    title: "Welcome",
    description: "We are currently working on updating the docs.",
    category: "Introduction",
    parent: "",
    children: [],
    documentation: [
      {
        type: "overview",
      },
    ],
  };
}
