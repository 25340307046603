import { useNavigate } from "react-router-dom";
import styles from "./styles/DocumentationNavHeader.module.css";

interface DocumentationHeaderProps {
  title: string;
}

export default function DocumentationNavHeader({
  title,
}: DocumentationHeaderProps) {
  const navigate = useNavigate();

  return (
    <div className={styles.DocumentationNavHeader}>
      <span className={styles.CompanyName} onClick={() => navigate("/")}>
        JJDN
      </span>
    </div>
  );
}
