import styles from "./styles/DocumentationHeader.module.css";

interface DocumentationHeaderProps {
  category: string;
  title: string;
  description: string;
}

export default function DocumentationHeader({
  category,
  title,
  description,
}: DocumentationHeaderProps) {
  return (
    <div className={styles.DocumentationHeader}>
      <h1 className={styles.Category}>{category}</h1>
      <h1 className={styles.Title}>{title}</h1>
      <p className={styles.Description}>{description}</p>
    </div>
  );
}
