import cn from "classnames";
import { ChangeEvent, useState } from "react";
import { useSearchParams } from "react-router-dom";
import templates from "../doc-templates";
import styles from "./Documentation.module.css";
import DocumentationBody from "./documentation/DocumentationBody";
import DocumentationBreadcrumb from "./documentation/DocumentationBreadcrumb";
import DocumentationHeader from "./documentation/DocumentationHeader";
import DocumentationNav from "./documentation/DocumentationNav";
import DocumentationNavHeader from "./documentation/DocumentationNavHeader";
import DocumentationSearch from "./documentation/DocumentationSearch";
import SupportContainer from "./support/SupportContainer";

export default function Documentation() {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page");
  const search = searchParams.get("search");
  const [selected, setSelected] = useState(page?.toString() || "Welcome");
  const [showHelp, setShowHelp] = useState(false);

  const documentationTemplates = Object.values(templates).map((template: any) =>
    template()
  );

  const handleHelpClick = () => {
    setShowHelp(!showHelp);
  };

  const handleOnClick = (title: string) => {
    setSearchParams({ page: title });
    setSelected(title);
  };

  const handleSearchClick = () => {
    if (searchParams.has("search")) {
      searchParams.delete("search");
      setSearchParams({ ...searchParams, page: page || "" });
    } else {
      setSearchParams({ page: page || "", search: "" });
    }
  };

  const handleSearchChange = (e: ChangeEvent) => {
    e.preventDefault();
    const element = e.currentTarget as HTMLInputElement;
    setSearchParams({ page: page || "", search: element.value });
  };

  const renderSelectedTemplate = () => {
    // Render default
    if (!selected) return <></>;

    const selectedTemplate = documentationTemplates.find(
      (template) => template.title === selected
    );

    //Render 404 view
    if (!selectedTemplate) return <></>;

    return (
      <>
        <DocumentationBreadcrumb
          parentPage={selectedTemplate.parent}
          currentPage={selected}
          onSearchClick={handleSearchClick}
          isSearching={searchParams.has("search")}
          onSearchChange={handleSearchChange}
          onHelpClick={handleHelpClick}
          onLinkClick={handleOnClick}
        />

        <div className={styles.content}>
          {searchParams.has("search") ? (
            // Render search window over content
            <DocumentationSearch
              templates={documentationTemplates}
              searchValue={search?.toString()}
              onClick={handleOnClick}
            />
          ) : (
            <>
              <DocumentationHeader
                category={selectedTemplate.category}
                title={selectedTemplate.title}
                description={selectedTemplate.description}
              />

              <DocumentationBody
                templates={documentationTemplates}
                documentation={selectedTemplate.documentation}
                children={selectedTemplate.children}
                onClick={handleOnClick}
              />
            </>
          )}
        </div>
      </>
    );
  };

  return (
    <div className={styles.Documentation}>
      {showHelp && <SupportContainer onClick={handleHelpClick} />}

      <div className={cn(styles.DocumentationNav, "hideOnMobile")}>
        <DocumentationNavHeader title={selected} />
        <DocumentationNav
          templates={documentationTemplates}
          onClick={handleOnClick}
          selectedLink={selected}
        />
      </div>
      <div className={styles.DocumentationPage}>{renderSelectedTemplate()}</div>
    </div>
  );
}
