import cn from "classnames";
import { useState } from "react";
import { FiThumbsDown, FiThumbsUp } from "react-icons/fi";
// import { trackRatingClicked } from "../../../lib/ga";
import styles from "./styles.module.css";

export default function Rating() {
  const [selected, setSelected] = useState<string | null>(null);

  const handleSetSelected = (selectedRating: string) => {
    // trackRatingClicked({ rating: selectedRating });
    setSelected(selectedRating);
  };

  return (
    <div className={styles.Rating}>
      <FiThumbsUp
        className={cn(styles.ThumbsUp, {
          [styles.selected]: selected === "thumbs-up",
        })}
        onClick={() => handleSetSelected("thumbs-up")}
      />
      <FiThumbsDown
        className={cn(styles.ThumbsDown, {
          [styles.selected]: selected === "thumbs-down",
        })}
        onClick={() => handleSetSelected("thumbs-down")}
      />
    </div>
  );
}
