import { ReactNode } from "react";

interface DocumentationLayoutProps {
  children: ReactNode;
}

export default function DocumentationLayout({
  children,
}: DocumentationLayoutProps) {
  return <div>{children}</div>;
}
