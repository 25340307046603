import CreateEventTemplate from "./events/CreateEvent";
import EventsTemplate from "./events/Events";
import WelcomeTemplate from "./welcome/Welcome";

const templates = {
  // Default Template
  WelcomeTemplate,

  // Manage Events Templates
  EventsTemplate,
  CreateEventTemplate
};

export default templates;
