import styles from "./navbar.module.css";

export default function Navbar() {
  return (
    <div className={styles.Navbar}>
      <div className={styles.Links}>
        <div>
          {/* <img
            src={`${process.env.PUBLIC_URL}/assets/photos/jjdn.jpeg`}
            alt="JJDN"
            className={styles.image}
          /> */}
          <h1>JJDN</h1>
        </div>
        <div>
          {/* <a className="hideOnMobile" href="/about">
            About
          </a> */}
          <a href="/documentation">Documentation</a>
          {/* <a className="hideOnMobile" href="/services">
            Services
          </a> */}
          {/* <MenuSVG /> */}
        </div>
      </div>
    </div>
  );
}
