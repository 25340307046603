import styles from "./styles.module.css";

export default function Footer() {
  return (
    <div className={styles.Footer}>
      <h1>JJDN</h1>
      {/* <div className={styles.IconsContainer}>
        <a></a>
        <a
          className={styles.icon}
          href="https://www.linkedin.com/in/darius-harrison/"
        >
          <FiLinkedin />
        </a>
        <a
          className={styles.icon}
          href="https://www.facebook.com/darius.harrison/"
        >
          <FiFacebook />
        </a>
        <a
          className={styles.icon}
          href="https://www.instagram.com/dariusharrison/"
        >
          <FiInstagram />
        </a>
      </div> */}
      <div>
        <span className={styles.copyright}>Copyright © 2024</span>
      </div>
    </div>
  );
}
