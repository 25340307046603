import { ChangeEvent } from "react";
import { ReactComponent as CloseSVG } from "../../icons/close.svg";
import { ReactComponent as HelpSVG } from "../../icons/help.svg";
import { ReactComponent as SearchSVG } from "../../icons/search.svg";
import styles from "./styles/DocumentationBreadcrumb.module.css";

interface DocumentationBreadcrumbProps {
  parentPage: string;
  currentPage: string;
  isSearching: boolean;
  onSearchClick: () => void;
  onHelpClick: () => void;
  onSearchChange: (e: ChangeEvent) => void;
  onLinkClick: (title: string) => void;
}

export default function DocumentationBreadcrumb({
  parentPage,
  currentPage,
  isSearching,
  onSearchClick,
  onHelpClick,
  onSearchChange,
  onLinkClick,
}: DocumentationBreadcrumbProps) {
  const renderSearchBar = () => {
    return (
      <input
        className={styles.SearchBar}
        placeholder="Search "
        onChange={(e) => onSearchChange(e)}
      />
    );
  };

  return (
    <div className={styles.DocumentationBreadcrumb}>
      {isSearching ? (
        renderSearchBar()
      ) : (
        <div className={styles.Breadcrumb}>
          <span
            className={styles.ParentPage}
            onClick={() => onLinkClick("Welcome")}
          >
            Documentation
          </span>
          {parentPage && (
            <>
              <span className={styles.divider}>/</span>
              <span
                className={styles.ParentPage}
                onClick={() => onLinkClick(parentPage)}
              >
                {parentPage}
              </span>
            </>
          )}
          <span className={styles.divider}>/</span>
          <span className={styles.CurrentPage}>{currentPage}</span>
        </div>
      )}
      <div className={styles.icons}>
        {isSearching ? (
          <CloseSVG className={styles.icon} onClick={() => onSearchClick()} />
        ) : (
          <SearchSVG className={styles.icon} onClick={() => onSearchClick()} />
        )}
        <HelpSVG className={styles.icon} onClick={() => onHelpClick()} />
      </div>
    </div>
  );
}
