import { ReactComponent as SendSVG } from "../../icons/send.svg";
import { ReactComponent as StarSVG } from "../../icons/star.svg";
import { ReactComponent as SupportSVG } from "../../icons/support.svg";
import styles from "./styles/SupportContainer.module.css";

interface SupportContainerProps {
  onClick: () => void;
}

export default function SupportContainer({ onClick }: SupportContainerProps) {
  return (
    <div className={styles.SupportContainer}>
      <div className={styles.Top} onClick={() => onClick()}>
        <SupportSVG />
        <h1>Welcome User</h1>
        <p>How can we help?</p>
      </div>
      <div className={styles.Bottom}>
        {/* Break out into component */}
        <div className={styles.SupportCard}>
          <p className={styles.title}>Give us feedback</p>
          <div className={styles.starRatings}>
            <StarSVG />
            <StarSVG />
            <StarSVG />
            <StarSVG />
            <StarSVG />
          </div>
          <div className={styles.feedback}>
            <input />
            <SendSVG />
          </div>
        </div>
        <div className={styles.SupportCard}>
          <p className={styles.title}>Contact us</p>
          <div className={styles.contact}>
            <button>
              <SendSVG />
              Send us quick message
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
